<div class="container">
  <h6>Aanpassingen aan de KOLK volgens AWV sinds 13/07/24 - V18.1</h6>
  <ul>
    <li><b>Kolk : Buis opgesplitst in buis horizontaal en buis verticaal -><br>Bij kolken vóór 13/07/24 waarbij geen informatie is ingevuld bij buis verticaal, zal op het PDF-formulier de buis zonder opsplitsing worden weergegeven.</b>
      <span class="note"><br>(in de excels en andere pagina's worden de huidige waardes getoond bij buis horizontaal)</span>
    </li>
    <li>Nieuwe waarde: <b>Type kolk : 'Beton' of 'Gietijzer'</b></li>
    <li>Aanpassing waarde: 'Aansluiting op open gracht' wordt -><br><b>'Aansluiting op vrije uitstroom? (bufferbekken/gracht/wadi)'</b></li>
    <li>Voor <b>projecten in opdracht van AWV</b>: bij KOLK: 2 extra schetsen + 2 verschillende lambertcoördinaten verplicht. AID-nummer & Ident8 instelbaar op de werf</li>
  </ul>

  <h6>Algemene verbeteringen sinds 13/07/24 - V18.1</h6>
  <ul>
    <li>Nieuwe <b>schademeldingsmodule</b></li>
    <li><b>Meerwerken: verbeteringen design, werking & pdf + nieuwe waarde = 'Activiteit'</b></li>
    <li>Meerwerk- en schademeldingpdfs genereren van het gehele project</li>
    <li><b>Straatsuggesties per project nu ook zichtbaar bij het creëeren van nieuwe huisaansluitingen,onvoorziene werken,schademeldingen en de kolken in de mobiele app</b></li>
    <li>Nieuwe taal mobiele app: Pools</li>
    <li>Mobiele app verbeteringen design en werking</li>
    <li>Betere foutopvang, sneller behandelen van bugs</li>
    <li><b>Bulk wijzigen:</b> meerdere huis-/wachtaansluitingen of kolken tegelijk wijzigen</li>
  </ul>

  <div class="version">Laatste versie mobiele app: V18.1</div>
</div>
